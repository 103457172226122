<template>
	<v-container fluid class="pt-0 pb-O">
		<ExerciceComponent />
	</v-container>
</template>

<script>
import ExerciceComponent from '@/components/exercice/Index.vue';

export default {
	name: 'Exercice',
	components: {
		ExerciceComponent,
	},
};
</script>
